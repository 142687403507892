import React from "react";
import { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import MenuBook from '@mui/icons-material/MenuBook';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import TypedText from './Components/TypedText';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./Album.css"

import ReactRecaptcha3 from 'react-google-recaptcha3';
import { createClient } from '@supabase/supabase-js'
import LogRocket from 'logrocket';
LogRocket.init('sjbnxo/tuitiontpt');

// Create a single supabase client for interacting with your database
const supabase = createClient('https://qamvdqkxwsmbjviksvef.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFhbXZkcWt4d3NtYmp2aWtzdmVmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc2MTgxODIsImV4cCI6MjAwMzE5NDE4Mn0.MV9b91Zjy4N884dRmSq746rr-qb_m3rxhvxIDbRHgs8')


var introText = `Welcome to Tuition-GPT.com. Your personal AI tutor trained with school text books. As of now, I have been trained with most of the CBSE text books from first standard to tenth standard. Note that I am trained only with CBSE (NCERT Publication) textbooks as of now. If your school is using a private publisher CBSE textbook like Oxford publications etc, the content in the text books may not match exatcly with my knowledge. 

Although I will try my best to answer your questions.\n\n If you would like this AI to be trained with more textbooks, connect with tanay@tanay.co.in, or submit a request on https://forms.gle/XnNNBRbp9CKmzvWh9

:-)
`

const curriculumGradeSubjectsMapping = {

  "cbse": {
    "1": {
      "english": "English",
      "maths": "Mathematics"
    },
    "2": {
      "english": "English",
      "maths": "Mathematics"
    },
    "3": {
      "english": "English",
      "maths": "Mathematics",
      "evs": "Environmental Studies"
    },
    "4": {
      "evs": "Environmental Studies"
    },
    "5": {
      "maths": "Mathematics",
      "evs": "Environmental Studies"
    },
    "6": {
      "english": "English",
      "maths": "Mathematics",
      "social": "Social Sciences",
      "science": "Science"
    },
    "7": {
      "english": "English",
      "maths": "Mathematics",
      "social": "Social Sciences",
      "science": "Science"
    },
    "8": {
      "english": "English",
      "maths": "Mathematics",
      "social": "Social Sciences",
      "science": "Science"
    },
    "9": {
      "english": "English",
      "maths": "Mathematics",
      "social": "Social Sciences",
      "science": "Science"
    },
    "10": {
      "english": "English",
      "maths": "Mathematics",
      "social": "Social Sciences",
      "science": "Science",
      "hpe": "Health and Physical Education"
    },
    "11": {
      "accountancy": "Accountancy",
      "biotechnology": "Biotechnology",
      "business-studies": "Business Studies",
      "chemistry": "Chemistry",
      "computer-science": "Computer Science",
      "economics": "Economics",
      "english": "English",
      "fine-art": "Fine Art",
      "geography": "Geography",
      "health-and-physical-education": "Health and Physical Education",
      "history": "History",
      "home-science": "Home Science",
      "informatics-practices": "Informatics Practices",
      "knowledge-traditions-practices-of-india": "Knowledge Traditions & Practices of India",
      "mathematics": "Mathematics",
      "physics": "Physics",
      "political-science": "Political Science",
      "sociology": "Sociology"
    },
    "12": {
      "accountancy": "Accountancy",
      "business-studies": "Business Studies",
      "chemistry": "Chemistry",
      "computer-science": "Computer Science",
      "economics": "Economics",
      "english": "English",
      "fine-art": "Fine Art",
      "geography": "Geography",
      "history": "History",
      "home-science": "Home Science",
      "informatics-practices": "Informatics Practices",
      "mathematics": "Mathematics",
      "physics": "Physics",
      "political-science": "Political Science",
      "psychology": "Psychology",
      "sociology": "Sociology"
    }
  },

  "karnataka": {
    "1": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science"
    },
    "2": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science"
    },
    "3": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science"
    },
    "4": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science"
    },
    "5": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science"
    },
    "6": {
      "english": "English",
      "maths": "Mathematics",
      "physical-education": "Physical Education",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "7": {
      "english": "English",
      "maths": "Mathematics",
      "physical-education": "Physical Education",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "8": {
      "english": "English",
      "maths": "Mathematics",
      "physical-education": "Physical Education",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "9": {
      "english": "English",
      "maths": "Mathematics",
      "physical-education": "Physical Education",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "10": {
      "english": "English",
      "maths": "Mathematics",
      "physical-education": "Physical Education",
      "social-science": "Social Sciences"
    }
  },
  "tamilnadu": {
    "1": {
      "english": "English",
      "maths": "Mathematics",
      "environmental-science": "Environmental Science"
    },
    "2": {
      "english": "English",
      "maths": "Mathematics",
      "environmental-science": "Environmental Science"
    },
    "3": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "4": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "5": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "6": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "7": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "8": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "9": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "10": {
      "english": "English",
      "maths": "Mathematics",
      "science": "Science",
      "social-science": "Social Sciences"
    },
    "11": {
      "accountancy": "Accountancy",
      "bio-botany": "Bio-Botany",
      "bio-chemistry": "Bio-Chemistry",
      "bio-zoology": "Bio-Zoology",
      "botany": "Botany",
      "business-mathematics-and-statistics": "Business Mathematics and Statistics",
      "chemistry-1": "Chemistry 1",
      "chemistry-2": "Chemistry 2",
      "commerce": "Commerce",
      "communicative-english": "Communicative English",
      "computer-applications": "Computer Applications",
      "computer-science": "Computer Science",
      "computer-technology": "Computer Technology",
      "economics": "Economics",
      "english": "English",
      "general-nursing": "General Nursing",
      "geography": "Geography",
      "history": "History",
      "home-science": "Home Science",
      "mathematics-1": "Mathematics 1",
      "mathematics-2": "Mathematics 2",
      "micro-biology": "Micro Biology",
      "nutrition-and-dietetics": "Nutrition and Dietetics",
      "physics-1": "Physics 1",
      "physics-2": "Physics 2",
      "political-science": "Political Science",
      "statistics": "Statistics",
      "zoology": "Zoology"
    },
    "12": {
      "accountancy": "Accountancy",
      "auditing": "Auditing",
      "bio-botany": "Bio-Botany",
      "bio-chemistry": "Bio-Chemistry",
      "bio-zoology": "Bio-Zoology",
      "botany": "Botany",
      "business-mathematics-and-statistics": "Business Mathematics and Statistics",
      "chemistry-1": "Chemistry 1",
      "chemistry-2": "Chemistry 2",
      "commerce": "Commerce",
      "communicative-english": "Communicative English",
      "computer-applications": "Computer Applications",
      "computer-science": "Computer Science",
      "computer-technology": "Computer Technology",
      "economics": "Economics",
      "english": "English",
      "general-nursing": "General Nursing",
      "geography": "Geography",
      "history": "History",
      "home-science": "Home Science",
      "mathematics-1": "Mathematics 1",
      "mathematics-2": "Mathematics 2",
      "micro-biology": "Micro Biology",
      "nutrition-and-dietetics": "Nutrition and Dietetics",
      "physics-1": "Physics 1",
      "physics-2": "Physics 2",
      "political-science": "Political Science",
      "statistics": "Statistics",
      "zoology": "Zoology"
    }
  }
}


//If localhost, MaxFreeQuestions = 1000, else 0
var MaxFreeQuestions = 0;
if (window.location.hostname === "localhost") {
  MaxFreeQuestions = 1000;
}

var serverUrl = "https://tuition-gpt-main-2e2e764.zuplo.app/ask"

// var serverUrl = "https://api-camel.tanay.in/ask"

function replaceWithBr(text) {
  //If text is defined and is not null or empty
  if (text) {
    console.log("In replace function")
    console.log("Recieved text")
    console.log(text)
    //Remove \n if present at the beginning and end of text
    var newText = text.replace(/^\n+|\n+$/g, '');
    newText = newText.replace(/\n/g, "<br />")
    console.log("Returning text")
    console.log(newText)
    return newText;
  }
}
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://tuition-gpt.com/">
        Tuition GPT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const theme = createTheme();
export default function Album() {

  var gradeMenuItemsString = localStorage.getItem('gradeMenuItems') || "";
  var gradeMenuItemsStringArray = gradeMenuItemsString.split(",");

  var GradeMenuItemsArray = [];
  for (var i = 0; i < gradeMenuItemsStringArray.length; i++) {
    GradeMenuItemsArray.push(<MenuItem value={gradeMenuItemsStringArray[i]}>Grade - {gradeMenuItemsStringArray[i]}</MenuItem>)
  }
  const [gradeMenuItems, setGradeMenuItems] = useState(GradeMenuItemsArray);

  var subjectMenuItemsString = localStorage.getItem('subjectMenuItems') || '';
  if (subjectMenuItemsString != '') {

    var subjectMenuItemsStringArray = JSON.parse(subjectMenuItemsString);
    var subjectMenuItemsArray = [];
    for (var sub in subjectMenuItemsStringArray) {
      subjectMenuItemsArray.push(<MenuItem value={sub}>{subjectMenuItemsStringArray[sub]}</MenuItem>)
    }
  }
  else {
    subjectMenuItemsArray = [];
  }

  const [subjectMenuItems, setSubjectMenuItems] = useState(subjectMenuItemsArray);

  const login = async () => {
    //clear query and response
    setQuery("");
    setAnswer("");
    //clear query and response in localstorage
    localStorage.removeItem('query');
    localStorage.removeItem('answer');
    setUserEmail("");
    //clear useremail in localstorage
    const { user, session, error } = await supabase.auth.signInWithOAuth({
      provider: 'google'
    })
  }

  // const captchaRef = useRef(null)
  // Add a useEffect hook to update the answer whenever its value changes
  // useEffect(() => {
  //   // Set answer to localstorage
  //   localStorage.setItem('answer', answer);
  // }, [answer]);

  //answerFetched - default to yes if answer is present in localstorage
  const [answerFetched, setAnswerFetched] = useState(localStorage.getItem('answer') ? true : false);
  const [answer, setAnswer] = useState(localStorage.getItem('answer') || introText);


  useEffect(() => {

    //Set answer, answerFetched to localstorage

    localStorage.setItem('answer', answer);
    localStorage.setItem('answerFetched', answerFetched);

    console.log("In useEffect")
    console.log("Answer")
    console.log(answer)
    if (answer == "") {
      setAnswer(introText);
      localStorage.setItem('answer', introText);
      setAnswerFetched(true);
    }

    // Supabase session
    supabase.auth.getSession().then((session) => {

      console.log("Session")
      console.log(session)
      //If session.data.session.user.user_metadata.email exists, set userIsLoggedIn to true

      if (session.data.session) {
        setUserIsLoggedIn(true);
        var email = session.data.session.user.user_metadata.email
        setUserEmail(email);
        localStorage.setItem('userEmail', email);
        console.log("Setting user email to " + email)
        LogRocket.identify(email, {
          email: email,
        });
      }
      else {
        setUserIsLoggedIn(false);
        //Clear localstorage userEmail
        localStorage.removeItem('userEmail');
      }
    })


    ReactRecaptcha3.init("6LdZpskmAAAAAISXWxPmogaPPqj3sEcLMj7nUR31").then(
      (status) => {
        console.log("Captcha loaded successfully")
        console.log(status);
      }
    );
  }, [])


  const [loading, setLoading] = useState(false);

  //Get default value from localstorage
  const [curriculum, setCurriculum] = useState(localStorage.getItem('curriculum') || "");
  const [grade, setGrade] = useState(localStorage.getItem('grade') || "");
  const [subject, setSubject] = useState(localStorage.getItem('subject') || "");
  const [alertIsLive, setAlertIsLive] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [query, setQuery] = useState(localStorage.getItem('query') || "");



  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || "");
  const [numberOfQuestionsAsked, setNumberOfQuestionsAsked] = useState(localStorage.getItem('numberOfQuestionsAsked') || 0);

  function onEnter(event) {
    console.log("Key pressed")
    console.log(event)
    console.log(event.keyCode)
    console.log(event.key)
    if (event.key === 'Enter' && !event.shiftKey) {
      console.log("Enter pressed")
      //If all of Curriculum, Grade and Subject are not selected, show an alert


      handleQuery();

    }
  }
  function handleQuery() {

    //If query length is less than 10, show an alert
    if (query.length < 10) {
      setAlert("Please enter a question with at least 10 characters", "error")
      return;
    }
    //If query length is greater than 500, show an alert that the question is too long
    if (query.length > 500) {
      setAlert("Please enter a question with less than 500 characters", "error")
      return;
    }

    if (curriculum === "" || grade === "" || subject === "") {
      setAlert("Please select Curriculum, Grade and Subject", "error")
      return;
    }

    //Check if number of questions asked is greater than 5, if yes, show an alert asking user to login
    if ((numberOfQuestionsAsked >= MaxFreeQuestions) && !userIsLoggedIn) {
      setAlert("Please login to ask questions.", "error")
      return;
    }
    setLoading(true);

    ReactRecaptcha3.getToken().then(
      (token) => {
        console.log(token);

        console.log("Querying GPT-3")
        console.log("Curriculum: " + curriculum)
        console.log("Grade: " + grade)
        console.log("Subject: " + subject)
        console.log("Query: " + query)
        console.log("Token: " + token)
        console.log("User email: " + userEmail)
        //Make a post request to serverUrl
        fetch(serverUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            curriculum: curriculum,
            grade: grade,
            subject: subject,
            query: query,
            token: token,
            email: userEmail,
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            var receivedResponse = "";
            var finalAnswer = "";
            //If data.response.response exists and is not null or empty, set receivedResponse to data.response.response

            if (data.response !== "") {
              receivedResponse = data.response;
              finalAnswer = replaceWithBr(receivedResponse);
            }
            if (data.status == 429) {
              finalAnswer = introText
              finalAnswer = replaceWithBr(finalAnswer);
              setAlert("You have exceeded your daily quota. Please try again tomorrow :-)", "error")
            }


            setAnswer(finalAnswer);
            console.log("Setting Answer to ")
            console.log(finalAnswer)
            //Set answer to localstorage
            localStorage.setItem('answer', finalAnswer);
            //Increment number of questions asked
            var numberOfQuestionsAsked = parseInt(localStorage.getItem('numberOfQuestionsAsked') || 0);
            numberOfQuestionsAsked = numberOfQuestionsAsked + 1;
            setNumberOfQuestionsAsked(numberOfQuestionsAsked);
            localStorage.setItem('numberOfQuestionsAsked', numberOfQuestionsAsked);
            setAnswerFetched(true);
            setLoading(false);
            if (userIsLoggedIn && data.status != 429) {
              if (data.remainingBalance !== "NA") {
                setAlert("Your remaining balance is " + data.remainingBalance + " question(s)", "success")
              }
            }
          }
          )
          .catch((error) => {
            console.error('Error:', error);
            setAlert("Error in processing. TuitionGPT may be overloaded with traffic. Please try later. ", "error")
            setAnswer("Error in processing. TuitionGPT may be overloaded with traffic. Please try later. ")
            setLoading(false);
          }
          );


      },
      (error) => {
        console.log("Error in getting captcha token")
        console.log(error);
      }
    );


  }
  function setAlert(message, severity) {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertIsLive(true);
    //setAlertIsLive(false) after 5 seconds
    setTimeout(() => {
      setAlertIsLive(false);
    }, 60000);
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          {/* A scholar or book icon */}
          <MenuBook sx={{ mr: 2 }} />
          {/* Dont show on mobiles */}
          <Typography variant="h6" color="inherit" noWrap
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}

          >
            Tuition GPT - Your personal AI school tutor
          </Typography>

          <Box sx={{ flexGrow: 1 }} /> {/* Add this to push the login button to the right */}
          {/* If userEmail is set, show userEmail as a tablet/pill, else show text Login */}
          {userEmail && userIsLoggedIn && <Typography variant="h6" color="inherit" noWrap> {userEmail} </Typography>}
          {/* Show a text button Login/Sign-up */}
          {!userIsLoggedIn && <Button color="inherit" onClick={login}>Login</Button>}

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event) => {
              //Supabase logout
              supabase.auth.signOut().then(() => {
                //Clear localstorage userEmail
                localStorage.removeItem('userEmail');
                setUserEmail("");
                setUserIsLoggedIn(false);
                //clear curriculum, grade, subject, query, answer
                setCurriculum("");
                setGrade("");
                setSubject("");
                setQuery("");
                setAnswer("");
                //clear curriculum, grade, subject, query, answer in localstorage
                localStorage.removeItem('curriculum');
                localStorage.removeItem('grade');
                localStorage.removeItem('subject');
                localStorage.removeItem('query');
                localStorage.removeItem('answer');
                localStorage.removeItem('gradeMenuItems');
                localStorage.removeItem('subjectMenuItems');


                //Reload page
                window.location.reload();
              })
            }}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <main>

        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 3,
            pb: 6,
            px: 3,
            fontfamily: 'Roboto',
          }}
        >

          <Container>

            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              sx={{
                mb: 1,
                fontFamily: 'Roboto',
              }}
            >
              Tuition GPT
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Your personal AI school tutor for CBSE (NCERT), Karnataka and Tamil Nadu State Board Textbooks
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={6}>
              {/* On large screens */}
              <Grid item xs={12} md={5} lg={5}>
                {/* Content for the first column */}
                {/* Three dropdowns arranged horizontally in single row with labels - Curriculum, Grade, Subject . The dropdowns have large font */}
                <>
                  <Box
                    component="form"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& > :not(style)': { m: 1, width: '33%' }, // Set width to '33%' for equal distribution
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl variant="outlined">
                      <InputLabel id="curriculum-label">Curriculum</InputLabel>
                      <Select

                        labelId="curriculum-label"
                        id="selected-curriculum"
                        label="Curriculum"
                        value={curriculum}
                        sx={{
                          fontWeight: "bold",
                        }}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setCurriculum(selectedValue);
                          localStorage.setItem('curriculum', selectedValue);

                          //Clear subject and grade
                          setSubject("");
                          setGrade("");
                          localStorage.removeItem('subject');
                          localStorage.removeItem('grade');

                          var grades = curriculumGradeSubjectsMapping[selectedValue];
                          console.log("Grades")
                          console.log(grades)

                          //Loop through all keys of the object

                          //Update Menu Items of Grade
                          var gradeMenuItems = [];
                          var gradeMenuItemsAsString = "";
                          for (var grade in grades) {
                            console.log("Processing Grade ")
                            console.log(grade)

                            gradeMenuItems.push(<MenuItem value={grade}>Grade - {grade}</MenuItem>)
                            gradeMenuItemsAsString = gradeMenuItemsAsString + grade + ",";
                          }
                          //Remove the last character in gradeMenuItemsAsString
                          gradeMenuItemsAsString = gradeMenuItemsAsString.slice(0, -1);
                          setGradeMenuItems(gradeMenuItems);
                          //convert to json store in localstorage
                          localStorage.setItem('gradeMenuItems', gradeMenuItemsAsString);


                        }}
                      >
                        <MenuItem value="cbse">CBSE (NCERT) (India)</MenuItem>
                        <MenuItem value="tamilnadu">Tamil Nadu State (India)</MenuItem>
                        <MenuItem value="karnataka">Karnataka State (India)</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="grade-label">Grade</InputLabel>
                      <Select
                        labelId="grade-label"
                        id="selected-grade"
                        label="Grade"
                        sx={{
                          fontWeight: "bold",
                        }}
                        value={grade}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setGrade(selectedValue);
                          localStorage.setItem('grade', selectedValue);
                          //Clear Subject
                          setSubject("");
                          localStorage.removeItem('subject');

                          var subjects = curriculumGradeSubjectsMapping[curriculum][selectedValue];
                          console.log("Subjects")
                          console.log(subjects)

                          //Loop through all keys of the object, and create menu items
                          var subjectMenuItems = [];
                          for (var subject in subjects) {
                            console.log("Processing Subject ")
                            console.log(subject)

                            subjectMenuItems.push(<MenuItem value={subject}>{subjects[subject]}</MenuItem>)
                          }
                          setSubjectMenuItems(subjectMenuItems);

                          //Store as json in localstorage
                          localStorage.setItem('subjectMenuItems', JSON.stringify(subjects));


                        }}
                      >
                        {/* Loop through and render all elements of gradeMenuItems */}
                        {gradeMenuItems}

                      </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                      <InputLabel id="subject-label">Subject</InputLabel>
                      <Select
                        labelId="subject-label"
                        id="selected-subject"
                        label="Subject"
                        value={subject}
                        sx={{
                          fontWeight: "bold",
                        }}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setSubject(selectedValue);
                          localStorage.setItem('subject', selectedValue);
                        }}
                      >
                        {subjectMenuItems}
                      </Select>
                    </FormControl>
                  </Box>
                  {alertIsLive &&
                    <Alert severity={alertSeverity} onClose={() => { setAlertIsLive(false) }} sx={{ mb: 2 }}>
                      {alertMessage}
                    </Alert>
                  }


                  {/* A large rectangular multiline text box with large font and a submit button */}
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-multiline-static"
                      label="Ask your question here"
                      multiline
                      rows={8}
                      sx={{
                        fontWeight: "bold",
                      }}
                      inputProps={{
                        sx: {

                          fontfamily: 'Helvetica Neue',
                          fontSize: 20,
                        }
                      }}
                      placeholder="Ask your question here.
                    Example - What is the difference between a square and a rectangle?
                    OR
                    How did Mrs. Sowerberry and Mrs. Bedwin treat Oliver Twist in the story? (80-100 words)
                    OR
                    Just copy-paste any type of fill in the blank or MCQ question that you need answer for.
                    "
                      variant="outlined"
                      onKeyDown={onEnter}
                      value={query}
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setQuery(selectedValue);
                        localStorage.setItem('query', selectedValue);
                      }}
                      disabled={loading || ((numberOfQuestionsAsked >= MaxFreeQuestions) && !userIsLoggedIn)}
                    />
                  </Box>
                  {/* Big Large Loading throbber shown only if loading is set to true */}
                  {loading &&
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        mt: 2,
                        mb: 0
                      }}
                    >
                      <CircularProgress />
                      <Typography variant="h6" align="center" gutterBottom>
                        Loading...
                      </Typography>
                      {/* Small font */}
                      <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 1, fontWeight: 'bold' }}>
                        After all, GPU is expensive. This runs on a TEENY WEENY server. This may take a few seconds to a couple of minutes depending on the number of users trying out Tuition-GPT now.
                      </Typography>
                    </Stack>
                  }
                  {/* Big Bold "Ask TutorGPT" button" */}
                  {/* Show a login button */}
                  {!userIsLoggedIn && numberOfQuestionsAsked >= MaxFreeQuestions &&
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        mt: 2,
                        mb: 3
                      }}
                    >
                      <Button variant="contained" size="large" onClick={login}
                        sx={{
                          mb: 1,
                          backgroundColor: "green",
                        }}
                      >
                        Login to ask questions!
                      </Button>
                    </Stack>
                  }
                  {
                    //If user is logged in or number of questions asked is less than  or equal to 5, show the button
                    (userIsLoggedIn || numberOfQuestionsAsked < MaxFreeQuestions) &&
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        mt: 2,

                      }}
                    >
                      {/* Show button only if loading is set to false */}
                      {!loading &&
                        <>

                          <Button variant="contained" size="large" onClick={handleQuery}
                            sx={{
                              mb: 1,
                            }}
                          >
                            Ask Tuition GPT
                          </Button>
                        </>
                      }
                    </Stack>
                  }
                </>
              </Grid>
              <Grid item xs={12} md={6} lg={6}
                sx={{
                  pl: 2,
                  pr: 2,
                }}
              >
                {/* Content for the second column */}

                {/* Show only if answerFetched is true */}
                {/* Text should be regular body size, font should look computer generated, easy to read and should preserve line breaks during display */}
                {/* Display like a cumputer terminal for chat with a black border around, and spacing on left and right */}
                <Box sx={{
                  border: 1,
                  borderColor: 'grey.500',
                  borderRadius: 1,
                  p: 2,
                  mt: 2,
                  mb: 2,
                  minHeight: 300,
                  overflow: 'auto',
                  backgroundColor: 'grey.100',
                }}>
                  {answerFetched && !loading &&
                    <>
                      <Typography variant="paragraph" align="left" gutterBottom
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                        }}
                      >

                        <TypedText text={answer} key={answer} />
                      </Typography>
                    </>
                  }
                </Box>
              </Grid>
            </Grid>



          </Container>
        </Box>
      </main>
      {/* Footer */}
      <Box sx={{
        bgcolor: 'background.paper',
        textAlign: 'center',
        p: 0
      }} component="footer">
        <Typography variant="caption" align="center" gutterBottom  >
          Built with ❤️ by <a href="https://www.linkedin.com/in/tanay-sai/">Tanay Sai</a> for Vishwak
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          sx={{
            //Bold text
            fontWeight: "bold",
            fontFamily: 'BlinkMacSystemFont'
          }}
        >
          <Link href="https://tanay.co.in/tuition-gpt-chatgpt-for-school-kids">I trained AI with all CBSE textbooks</Link> so I don't have to help my kid with homework everyday.
        </Typography>

        {/* Paragraph text in a box max width = 800px */}
        <Box
          sx={{
            maxWidth: 800,
            mx: 'auto',
            mt: 2,
            mb: 2,
          }}
        >

          <Typography variant="paragraph" align="left" sx={{ mb: 4 }}>
            If you would like to make a request to add more text books of any curriculum, or want to collaborate on this project to democratize knowledge, reach out to <Box component={'img'} src="https://i.imgur.com/GeKDfp9.png" sx={{
              height: 20
            }} /> or connect on <Link href="https://www.linkedin.com/in/tanay-sai/">Linkedin.</Link>

          </Typography>

          {/* Add a box to have all accordions inside to have text aligned to left */}
          <Box sx={{
            textAlign: 'left',
          }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  mt: 2,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>How to use TuitionGPT?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use TuitionGPT to ask any type of questions corresponding to the subject. You can ask questions from any chapter in the textbook. You can also ask questions from any type of question paper - fill in the blanks, MCQ, short answer, long answer, etc
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>Who can use TuitionGPT?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Students, Parents, Teachers, Tutors, anyone!

                  TuitionGPT is useful for students to help themselves with homework, and for parents to help their kids with homework. This is also useful for teachers to help students with homework. Teachers can prepare questions for giving assignments to their students. Example : A teacher can ask TuitionGPT "Generate 5 Multiple Choice Questions on the story 'The Hound of the Baskervilles'" and use the generated questions for giving assignments to students.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>How can teachers use TuitionGPT?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Teachers would often need to build exams with different question types. For example, a teacher may need to build an exam with 5 fill in the blanks, 5 MCQs, 5 short answer questions and 5 long answer questions.
                  <br /><br />
                  You can ask TuitionGPT to generate any type of question, from any specific unit, or topic.
                  <br /><br />
                  Example
                  <br /><br />
                  For CBSE, Social , 6th Standard - You may ask <i>Generate 5 Multiple Choice Questions on the topic 'Diversity and Discrimination'</i>
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>What subjects are trained on Tuition GPT?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  As of now, I have trained this on CBSE textbooks (Only the official NCERT textbooks) from Grade 6 to Grade 10. I will be adding more textbooks soon. If you have any requests, please reach out to <Box component={'img'} src="https://i.imgur.com/GeKDfp9.png" sx={{
                    height: 20
                  }} /> or submit your request <Link href="https://forms.gle/XnNNBRbp9CKmzvWh9">here</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>I can't find my curriculum or text book. Can you add it? </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Submit a request <Link href="https://forms.gle/XnNNBRbp9CKmzvWh9">here.</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>I have feedback or I want to report an issue. </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Please reach out to me at <Box component={'img'} src="https://i.imgur.com/GeKDfp9.png" sx={{
                    height: 20
                  }} /> or submit your feedback <Link href="https://forms.gle/XnNNBRbp9CKmzvWh9">here</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>Important Terms of Service</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  TuitionGPT is not associated with CBSE / NCERT or any educational authority. The answers generated by TuitionGPT are not verified by any educational authority. The answers generated by TuitionGPT are not guaranteed to be accurate. Use the service at discretion. TuitionGPT is not responsible for any type of losses including but not limited to financial losses, loss of reputation, loss of marks, loss of time, loss of data, loss of business, loss of marks in exams,  loss of goodwill, loss of life, loss of limb, loss of property, loss of anything that you can think of. Use the service at your own risk.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: "bold" }}>I am running out of credits. What should I do?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  To allow fair use of the free platform, each account is given 20 credits to try out the platform. If you are running out of credits, and if you are a teacher or a educationalist, please reach out at <Box component={'img'} src="https://i.imgur.com/GeKDfp9.png" sx={{
                    height: 20
                  }} />

                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>


        </Box>

        <Copyright />
        {/* Very small text, centered */}
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 1, }}>
          This site is protected by reCAPTCHA and the Google <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and <Link href="https://policies.google.com/terms">Terms of Service</Link> apply.
        </Typography>
      </Box>
      {/* End footer */}
    </ThemeProvider >
  );
}
